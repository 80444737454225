body {
  background-color: rgb(237, 228, 225); /*08050b, 190e23 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: hsla(0, 0%, 100%, 0.8); */
}
.net_err_text {
  color: red;
  font-size: 0.75rem;
  line-height: normal;
  text-align: center;
  font-weight: 700;
  color: rgba(233, 57, 57, 0.705);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
